import React from "react"

import { Text, TextType, TextFontSize } from "../components/Text"

import "./page.styles.scss"

const Contact = () => {
  return (
    <div className={"page"}>
      <Text type={TextType.H3} size={TextFontSize.big}>
        Contact
      </Text>
      <Text type={TextType.PARAGRAPH}>
         In case of any questions or concerns regarding this Website and our activity, we encourage you to contact us at:
      </Text>
      <Text type={TextType.PARAGRAPH}>contact.hellojs@gmail.com</Text>
    </div>
  )
}

export default Contact
